import "./responsive.css";
import "./App.css";

import Header from "./components/Header";
import Portfolio from "./components/Portfolio";
import Proces from "./components/Proces";
import ForYou from "./components/ForYou";
import Testimonials from "./components/Testimonials";
import Pricing from "./components/Pricing";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import { useEffect } from "react";
import ContactModal from "./components/ContactModal";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function App() {
  useEffect(() => {
    const cursor = document.querySelector(".custom-cursor");
    const innerCircle = document.createElement("div");
    const outerCircle = document.createElement("div");

    innerCircle.classList.add("inner-circle");
    outerCircle.classList.add("outer-circle");

    cursor.appendChild(innerCircle);
    cursor.appendChild(outerCircle);

    window.addEventListener("mousemove", (event) => {
      let x = event.pageX - innerCircle.offsetWidth / 2,
        y = event.pageY - innerCircle.offsetHeight / 2;

      innerCircle.style.left = `${x}px`;
      innerCircle.style.top = `${y}px`;

      setTimeout(() => {
        outerCircle.style.left = `${x - (outerCircle.offsetWidth - innerCircle.offsetWidth) / 2
          }px`;
        outerCircle.style.top = `${y - (outerCircle.offsetHeight - innerCircle.offsetHeight) / 2
          }px`;
      }, 100);
    });

    // Handle button hover state
    const buttons = document.querySelectorAll(".btn-primary");
    buttons.forEach((button) => {
      button.addEventListener("mouseenter", () => {
        outerCircle.style.border = "none";
        innerCircle.style.backgroundColor = `rgb(${getComputedStyle(
          document.documentElement
        ).getPropertyValue("--color-hover")})`;
        innerCircle.style.border = "none";
        innerCircle.style.scale = 2.5;
      });

      button.addEventListener("mouseleave", () => {
        innerCircle.style.scale = 1;
        outerCircle.style.border = "1px solid #fff";
        innerCircle.style.backgroundColor = "rgb(81, 255, 248)";
      });
    });

    const clickabels = document.querySelectorAll(".clickable");
    clickabels.forEach((button) => {
      button.addEventListener("mouseenter", () => {
        outerCircle.style.border = "none";
        innerCircle.style.backgroundColor = `rgb(${getComputedStyle(
          document.documentElement
        ).getPropertyValue("--color-hover")})`;
        innerCircle.style.border = "none";

        innerCircle.style.scale = 2.5;
      });

      button.addEventListener("mouseleave", () => {
        innerCircle.style.scale = 1;

        outerCircle.style.border = "1px solid #fff";
        innerCircle.style.backgroundColor = "rgb(81, 255, 248)";
      });
    });
  }, []);

  return (
    <>
      <div className="App">
        <Header />
        <Proces />
        <Portfolio />
        <ForYou />
        <Testimonials />
        <Pricing />
        <FAQ />
        <Footer />

      </div>
      <div className="custom-cursor"></div>
      <ContactModal />
    </>
  );
}

export default App;
