import "./Pricing.css";

const Pricing = () => {
  return (
    <div className="pricing-container" id="pricing">
      <div className="circle-gradient"></div>
      <header>
        <span>CLEAR & SIMPLE PRICING</span>
        <h3>Simple offer, simple pricing.</h3>
        <p>
          Our prices are divided into specific types of packages. We hope you
          will find the one that suits you!
        </p>
      </header>

      <div className="pricing-cards-wrapper">
        <div className="pricing-card">
          <div className="type-of-service" id="popular">
            Popular
          </div>
          <header>
            <h4>Launch</h4>
            <p>package</p>
          </header>
          <h3>€ 995</h3>
          <p>
            Fully functional and optimized <br /> landing page website.
          </p>
          <a
            href="https://calendly.com/weblience"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn-primary">Book a call today</button>
          </a>
          <ul>
            <li>Modern webdesign</li>
            <li>One landing page</li>
            <li>SEO optimization</li>
            <li>Device optimization</li>
            <li>Strategic planning</li>
            <li>Content upload</li>
            <li>Unlimited revisions</li>
          </ul>
        </div>
        <div className="pricing-card">
          <div className="type-of-service" id="best-value">
            Best value
          </div>

          <header>
            <h4>Custom</h4>
            <p>package</p>
          </header>
          <h3>€ 1495</h3>
          <p>
            Request the website that suits you <br /> the most...
          </p>
          <a
            href="https://calendly.com/weblience"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn-primary">Book a call today</button>
          </a>
          <ul>
            <li>Modern webdesign</li>
            <li>
              <span>Up to 3 pages</span>
            </li>
            <li>SEO optimization</li>
            <li>Device optimization</li>
            <li>Strategic planning</li>
            <li>Content upload</li>
            <li>Unlimited revisions</li>
            <li>
              <span>Priority support</span>
            </li>
            <li>
              <span>Advanced features</span>
            </li>
          </ul>
        </div>
        <div className="pricing-card">
          <header>
            <h4>Premium</h4>
            <p>package</p>
          </header>
          <h3>€ 2,295 +</h3>
          <p>
            Small business e-commerce, bigger <br /> webs offering services.
          </p>
          <a
            href="https://calendly.com/weblience"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn-primary">Book a call today</button>
          </a>
          <ul>
            <li>Modern webdesign</li>
            <li>
              <span>5+ page website</span>
            </li>
            <li>SEO optimization</li>
            <li>Device optimization</li>
            <li>Strategic planning</li>
            <li>Content upload</li>
            <li>Unlimited revisions</li>
            <li>
              <span>Advanced features</span>
            </li>
            <li>
              <span>E-commerce functions</span>
            </li>
            <li>
              <span>
                Ongoing support<p> (30 days)</p>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
