import "./Proces.css";

const Proces = () => {
  return (
    <div className="proces-container" id="processes">
      <div className="how-it-works-container">
        <div className="left-div">
          <div className="circle-gradient"></div>
          <span>HOW IT WORKS</span>
          <h3>
            Our simple process creates a seamless path from concept to
            <span> reality</span>.
          </h3>
          <a href="#pricing">
            <button className="btn-primary">See prices</button>
          </a>
        </div>
        <div className="right-div">
          <hr id="main-line" />
          <div className="scroll-container">
            <ul className="vertical-line-ul">
              <li className="item">
                <span className="circle">1</span>
                <h3>Discovery Call</h3>
                <p>
                  We start with a personalized call to understand your brand and
                  goals, ensuring tailored solutions.
                </p>
              </li>
              <li className="item">
                <span className="circle">2</span>
                <h3>Strategic Planning</h3>
                <p>
                  We need to learn more about your business. Strategic research
                  will help us deliver exponential results.
                </p>
              </li>
              <li className="item">
                <span className="circle">3</span>
                <h3>Custom Web</h3>
                <p>
                  Based on our strategy, we design and build a website that not
                  only looks great but also drives results.
                </p>
              </li>
              <li className="item">
                <span className="circle">4</span>
                <h3>Simple Revisions</h3>
                <p>
                  Need a tweak? We're here to make adjustments, ensuring your
                  website is just right.
                </p>
              </li>
              {/* <li className="item">
                <span className="circle">5</span>
                <h3>WEB Optimization</h3>
                <p>
                  Finally, we optimize your site for SEO, and devices so your
                  business can easily be found online.
                </p>
              </li>
              <li className="item">
                <span className="circle">6</span>
                <h3>Content Creation</h3>
                <p>
                  We develop engaging content that speaks to your audience and
                  enhances your brand’s online presence.
                </p>
              </li>
              <li className="item">
                <span className="circle">7</span>
                <h3>Marketing Strategy</h3>
                <p>
                  A comprehensive plan is put in place to ensure your marketing
                  efforts align with your business objectives.
                </p>
              </li>
              <li className="item">
                <span className="circle">8</span>
                <h3>Performance Analysis</h3>
                <p>
                  We track and analyze the performance of your website to
                  continuously improve and achieve better results.
                </p>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proces;
