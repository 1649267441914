// AutoplayCarousel.js
import React from "react";
import "./AutoplayCarousel.css";
import { cardDetailsTop } from "./carousel-config-top.js";
import { cardDetailsBottom } from "./carousel-config-bottom.js";
import CarouselItem from "./CarouselItem";
import Marquee from "react-fast-marquee";

export default function AutoplayCarousel() {
    return (
        <div className="carousel-container">
            <Marquee speed="40">
                {Object.keys(cardDetailsTop).map((detailKey) => {
                    return (
                        <CarouselItem
                            key={detailKey}
                            imgUrl={cardDetailsTop[detailKey].imgUrl}
                            imgTitle={cardDetailsTop[detailKey].title}
                        />
                    );
                })}
                {/* Duplicate the items for a seamless loop */}
                {Object.keys(cardDetailsTop).map((detailKey) => {
                    return (
                        <CarouselItem
                            key={`${detailKey}-duplicate`}
                            imgUrl={cardDetailsTop[detailKey].imgUrl}
                            imgTitle={cardDetailsTop[detailKey].title}
                        />
                    );
                })}
            </Marquee>

            <Marquee direction="right" speed="40">
                {Object.keys(cardDetailsBottom).map((detailKey) => {
                    return (
                        <CarouselItem
                            key={detailKey}
                            imgUrl={cardDetailsBottom[detailKey].imgUrl}
                            imgTitle={cardDetailsBottom[detailKey].title}
                        />
                    );
                })}
                {/* Duplicate the items for a seamless loop */}
                {Object.keys(cardDetailsBottom).map((detailKey) => {
                    return (
                        <CarouselItem
                            key={`${detailKey}-duplicate`}
                            imgUrl={cardDetailsBottom[detailKey].imgUrl}
                            imgTitle={cardDetailsBottom[detailKey].title}
                        />
                    );
                })}
            </Marquee>
        </div>
    );
}
