// carousel-config-bottom.js


import pizza from "../assets/imgs/pizza.png";
import auto from "../assets/imgs/auto.png";
import topanka from "../assets/imgs/topanka.png";
export const cardDetailsBottom = {
    0: {
        imgUrl: pizza,
        title: "Pizza restaurant modern website design",
    },
    1: {
        imgUrl: auto,
        title: "Car marketplace modern website design",
    },
    2: {
        imgUrl: topanka,
        title: "Sneaker shop modern website design",
    },
};
