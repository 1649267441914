import React, { useEffect } from "react";
import "./Menu.css";

const Menu = () => {
  useEffect(() => {
    const contactLink = document.querySelector(".contact-link-menu");
    const contactModal = document.querySelector(".contact-modal");
    const closeButton = document.querySelector(".close");
    const app = document.querySelector('.App');

    contactLink.addEventListener("click", (e) => {
      e.preventDefault(); // Prevent the default anchor behavior
      contactModal.style.display = "flex";
      app.style.opacity = 0.2;
    });

    closeButton.addEventListener("click", () => {
      contactModal.style.display = "none";
      app.style.opacity = 1;
    });

    return () => {
      contactLink.removeEventListener("click", () => { });
      closeButton.removeEventListener("click", () => { });
    };
  }, []);
  return (
    <div className="menu-container">
      <ul>
        <a href="#home" className="clickable">
          <li>HOME</li>
        </a>
        <a href="#processes" className="clickable">
          <li>PROCESSES</li>
        </a>
        <a href="#pricing" className="clickable">
          <li>PRICING</li>
        </a>
        <a href="#contact" className="clickable contact-link-menu">
          <li id="contact">CONTACT</li>
        </a>
      </ul>
    </div>
  );
};

export default Menu;
