import React from "react";
import "./ForYou.css";

const ForYou = () => {
  return (
    <div className="forYou-container">
      <div className="circle-gradient" id="first"></div>
      <div className="circle-gradient" id="second"></div>
      <h3>
        This is <span>for you</span> if you are
      </h3>
      <div className="options-container">
        <div className="option">
          <span className="circle big">1</span>
          <div>
            <h4>Building a startup</h4>
            <p>Looking to establish a strong digital presence.</p>
          </div>
        </div>
        <div className="option">
          <span className="circle big">3</span>
          <div>
            <h4>Running a small business</h4>
            <p>Aiming to grow your brand and increase conversions.</p>
          </div>
        </div>
        <div className="option">
          <span className="circle big">2</span>

          <div>
            <h4>Entrepreneur</h4>
            <p>Who need a custom website and strategic guidance.</p>
          </div>
        </div>
        <div className="option">
          <span className="circle big">4</span>
          <div>
            <h4>Business</h4>
            <p>Seeking to optimize their business strategy.</p>
          </div>
        </div>
      </div>
      <a
        href="https://calendly.com/weblience"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="btn-primary">Book a meeting</button>
      </a>
    </div>
  );
};

export default ForYou;
