// carousel-config-top.js

import vona from "../assets/imgs/vona.png";
import barber from "../assets/imgs/barber.png";
import dom from "../assets/imgs/dom.png";

export const cardDetailsTop = {
    0: {
        imgUrl: vona,
        title: "Fragrance modern website design",
    },
    1: {
        imgUrl: barber,
        title: "Barber modern website design",
    },
    2: {
        imgUrl: dom,
        title: "House modern website design",
    },

};
