import React, { useState, useEffect } from "react";
import "./Testimonials.css";
import Patrik from "../assets/imgs/Patrik.png";
import R from "../assets/imgs/R.svg";
import royacar from "../assets/imgs/royacar.svg";
import arrow from "../assets/imgs/arrow.svg";

const Testimonials = () => {
  const testimonials = [
    {
      image: Patrik,
      logo1: R,
      logo2: royacar,
      title: "Weblience Is Amazing! These Designs Are Like From Outerspace!",
      text: "Working with this agency was a game-changer for our business...",
      author: "Patrik Spaceman",
      company: "CEO of ROYACAR",
    },
    {
      image: Patrik,
      logo1: R,
      logo2: royacar,
      title: "Fantastic Service! Truly a Cut Above the Rest!",
      text: "This team took our vision and turned it into reality...",
      author: "John Doe",
      company: "CEO of FantasticCo",
    },
    {
      image: Patrik,
      logo1: R,
      logo2: royacar,
      title: "Outstanding Quality and Support!",
      text: "Their dedication to customer satisfaction is unmatched...",
      author: "Jane Smith",
      company: "Founder of Innovatech",
    },
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 500px)").matches);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 500px)");

    // Set the mobile view state when the screen resizes
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    let autoSlideInterval;

    if (isMobile) {
      // Auto-slide only on mobile
      autoSlideInterval = setInterval(() => {
        setAnimate(true); // Trigger animation
        setTimeout(() => {
          setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
          setAnimate(false); // Reset animation after switching
        }, 500); // Duration of animation
      }, 5000); // Change every 5 seconds
    }

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
      if (autoSlideInterval) clearInterval(autoSlideInterval);
    };
  }, [isMobile, testimonials.length]);

  const handleNext = () => {
    if (isMobile) {
      setAnimate(true);
      setTimeout(() => {
        setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
        setAnimate(false);
      }, 500); // Duration of animation
    } else {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }
  };

  const handlePrev = () => {
    if (isMobile) {
      setAnimate(true);
      setTimeout(() => {
        setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
        setAnimate(false);
      }, 500); // Duration of animation
    } else {
      setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
    }
  };

  return (
    <div className="testimonials-container">
      {/* Arrows will be hidden on mobile */}
      {!isMobile && (
        <>
          <div className="arrow" onClick={handlePrev}>
            <img className="arrow-testimonials clickable" src={arrow} alt="Prev" />
          </div>
          <div className="arrow right-arrow" onClick={handleNext}>
            <img className="arrow-testimonials clickable" src={arrow} alt="Next" />
          </div>
        </>
      )}
      <span>TESTIMONIALS</span>
      <h3>
        We Aim to Fulfil <br /> Your Expectations
      </h3>
      <div className={`testimonial-wrapper ${animate ? "slide" : ""}`}>
        <div className="rightSide">
          <img src={testimonials[currentTestimonial].image} alt="Patrik" />
        </div>
        <div className="leftSide">
          <header>
            <img src={testimonials[currentTestimonial].logo1} alt="Logo1" />
            <img src={testimonials[currentTestimonial].logo2} alt="Logo2" />
          </header>
          <h4>{testimonials[currentTestimonial].title}</h4>
          <p className="testimonial-wrapper-text">
            {testimonials[currentTestimonial].text}
          </p>
          <em>{testimonials[currentTestimonial].author}</em>
          <p id="company-name-testimonials">
            {testimonials[currentTestimonial].company}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
